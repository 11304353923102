<template>
  <div>
    <provision></provision>
  </div>
</template>

<script>
import Provision from "./../../../components/bulksms/provisions/index";
export default {
  data: () => ({
  }),
  components: {
    Provision,
  },
};
</script>