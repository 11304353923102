<template>
  <div>
    <div class="text-h6 font-weight-medium mb-4">
      {{ $t("bulksms.provision.title") }}
    </div>
    <div class="mt-4">
      <v-card class="rounded-md mb-4">
        <div class="d-flex align-center justify-start px-4 py-3 flex-wrap">
          <v-btn
            depressed
            @click="refreshItems()"
            :loading="loading.refresh"
            class="mr-2"
          >
            <v-icon left>mdi-refresh</v-icon>
            {{ $t("btn.refresh") }}
          </v-btn>
        </div>
      </v-card>
      <v-card>
        <v-card-title>
          <div class="font-weight-bold text-subtitle-1">
            {{ $t("bulksms.provision.list.title") }}
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-data-table
          :loading="loading.list"
          :headers="headers"
          :items="items"
          :options.sync="pagination"
          :footer-props="footerProps"
          :items-per-page="itemPerPage"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :server-items-length="meta.total"
        >
          <template v-slot:body="{ items }" v-if="!_.isEmpty(items)">
            <tbody>
              <template v-for="item in items">
                <tr :key="item.id">
                  <td style="min-width: 150px">
                    {{
                      $moment(item.provisionDate).format("YYYY-MM-DD HH:mm:ss")
                    }}
                  </td>
                  <td>
                    <v-chip class="table-chips text-body-2" color="fourth">
                      <span>{{ $utils.pad(item.smsCount, 2) }}</span>
                    </v-chip>
                  </td>
                  <td>
                    <v-chip
                      class="table-chips text-body-2"
                      color="success"
                      v-if="item.operation === 'credit'"
                    >
                      <span class="white--text">{{
                        $t("bulksms.provision.fields.operation.credit")
                      }}</span>
                    </v-chip>
                    <v-chip
                      class="table-chips"
                      color="danger"
                      v-else-if="item.operation === 'debit'"
                    >
                      <span class="white--text">{{
                        $t("bulksms.provision.fields.operation.debit")
                      }}</span>
                    </v-chip>
                  </td>
                  <td>
                    <v-chip class="table-chips text-body-2" color="fourth">
                      <span>{{ $utils.pad(item.smsPreviousBalance, 2) }}</span>
                    </v-chip>
                  </td>
                  <td>
                    <v-chip class="table-chips text-body-2" color="fourth">
                      <span>{{ $utils.pad(item.smsNewBalance, 2) }}</span>
                    </v-chip>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import ListMixin from "./../../../mixins/commons/list";
export default {
  mixins: [ListMixin],
  data: () => ({
    path: "/api/providers/bulksms/provisions",
  }),
  methods: {
    setHeaders() {
      this.headers = [
        {
          text: this.$t("bulksms.provision.fields.created.title"),
          value: "provisionDate",
          align: "start",
          sortable: true,
          width: 150,
        },
        {
          text: this.$t("bulksms.provision.fields.smsCount.title"),
          value: "smsCount",
          align: "start",
          sortable: false,
        },
        {
          text: this.$t("bulksms.provision.fields.operation.title"),
          value: "operation",
          align: "start",
          sortable: false,
        },
        {
          text: this.$t("bulksms.provision.fields.smsPreviousBalance.title"),
          value: "smsPreviousBalance",
          align: "start",
          sortable: false,
        },
        {
          text: this.$t("bulksms.provision.fields.currentBalance.title"),
          value: "smsNewBalance",
          align: "start",
          sortable: false,
        },
      ];
    },
  },
};
</script>